import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Dental } from "./Dental";
import { Ondoto } from "./Ondoto";
import { Onto } from "./Onto";
function Advantages() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAdvantages {
        nodes {
          heading
          sapn
          lists
        }
      }
    }
  `);

  const {
    allContentfulAdvantages: { nodes },
  } = data;
  return (
    <>
      <div className="advantages--outer-wrapper">
        <div className="advantages--wrapper">
          {nodes.map((n) => {
            return (
              <div className="advantages" key={n.sapn}>
                <h4>
                  {n.heading} <span>{n.sapn}</span>
                </h4>
                <ul>
                  {n.lists.map((l) => {
                    return (
                      <li key={l}>
                        <div className="advantage">
                          {n.sapn === "Methods" ? (
                            <Dental />
                          ) : n.sapn === "Root canal" ? (
                            <Onto />
                          ) : (
                            <Ondoto />
                          )}
                          <p>{l}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export { Advantages };
