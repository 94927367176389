import React from "react";
import { Image } from "../Image";
import { motion } from "framer-motion";
import { modalAni } from "../animation";
import { Back2 } from "../Back2";
function TreatModal({ Layout, setLayout, nonMutable }) {
  return (
    <>
      <div className="treat--modal">
        <motion.div className="treat--content--container" layoutId={Layout.id}>
          <Image
            classId="treatment--image"
            image={Layout.img}
            alt="dental care"
          />
          <motion.div
            className="treatment--text"
            variants={modalAni}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.h3>
              {Layout.title} <span>Treatment</span>
            </motion.h3>

            <motion.p>{Layout.body.body}</motion.p>

            <motion.button
              id="back--button"
              onClick={() => {
                setLayout({ ...nonMutable });
              }}
              aria-label="back"
            >
              <Back2 /> back
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}

export { TreatModal };
