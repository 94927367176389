import React, { useState } from "react";
import { ServicesHeader } from "./ServicesHeader";
import { TreatmentsWrapper } from "./TreatmentsWrapper";
import { BackToTop } from "../BackTotop";
import {Theme} from '../Theme';
function ServicesPage() {
  const [Inter, setInter] = useState({ state: true });

  return (
    <>
      <main className="services--main" id="services--home">
        <ServicesHeader setInter={setInter} />
        <TreatmentsWrapper />
        <div className="theme--and--top">
          {!Inter.state ? <BackToTop id="services--home" /> : ""}
          <Theme />
        </div>
      </main>
    </>
  );
}

export { ServicesPage };
