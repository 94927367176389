import React from "react";

function TreatmentTop() {
  return (
    <>
      <div className="Treatment--top">
        <h2>Treatments</h2>
        <p>Tap the cards to see more details</p>
      </div>
    </>
  );
}

export { TreatmentTop };
