import React,{useEffect} from "react";
import { Nav } from "../Nav";
import { ServicesHeroContainer } from "./ServicesHeroContainer";
import { Pattern } from "../homepage/Pattern";
import { useInView } from "react-intersection-observer";
function ServicesHeader({ setInter }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setInter({ state: inView });
  }, [inView, setInter]);
  return (
    <>
      <div className="header-wrapper" ref={ref}>
        <div className="header">
          <Nav />
          <ServicesHeroContainer />
        </div>
        <Pattern />
      </div>
    </>
  );
}

export { ServicesHeader };
