import React from "react";

function Back2() {
  return (
    <>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id='back2'
      >
        <path
          d="M10 0C4.47749 0 0 4.47749 0 10C0 15.5231 4.47749 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47749 15.5231 0 10 0ZM10 18.75C5.16752 18.75 1.25002 14.8325 1.25002 10C1.25002 5.16752 5.16752 1.25002 10 1.25002C14.8325 1.25002 18.75 5.16752 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75Z"
          fill="black"
        />
        <path
          d="M15.625 9.36313H5.87936L9.12938 6.06625C9.37314 5.82 9.37314 5.42002 9.12938 5.17376C8.885 4.92751 8.48938 4.92751 8.24562 5.17376L3.93375 9.54813C3.81686 9.66502 3.75 9.8294 3.75 9.9944C3.75 10.1588 3.81749 10.3232 3.93313 10.4407L8.245 14.815C8.48938 15.0619 8.885 15.0613 9.12876 14.815C9.37314 14.5682 9.37314 14.1688 9.12876 13.9219L5.87874 10.625H15.625C15.97 10.625 16.25 10.3425 16.25 9.99378C16.25 9.64565 15.97 9.36313 15.625 9.36313Z"
          fill="black"
        />
      </svg>
    </>
  );
}

export { Back2 };
