import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HeroText } from "../homepage/HeroText";
import { HeroImage } from "../homepage/HeroImage";
function ServicesHeroContainer() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulServicesHeader {
        nodes {
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          headerImage2 {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          body
        }
      }
    }
  `);

  const {
    allContentfulServicesHeader: {
      nodes: [{ title, body, headerImage, headerImage2 }],
    },
  } = data;

  return (
    <>
      <div className="hero--container">
        <HeroText title={title} body={body}  goto='gallery' value='gallery' id='header--link2'/>
        <HeroImage image={headerImage} classId="hero-image"  alt='doctor illustration'/>
        <HeroImage image={headerImage2} classId="hero-image2" alt='teeth smiling'/>
      </div>
    </>
  );
}

export { ServicesHeroContainer };
