import React from "react";

function Onto() {
  return (
    <>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.171 14.5956C16.0421 14.5345 15.8974 14.5 15.7429 14.5H13.8333L7.52414 16.7713L7.72384 17.6366L16.171 14.5956ZM8.14994 19.4832L16.5477 16.46L16.7173 15.7248C16.7385 15.6329 16.7464 15.5417 16.7423 15.4528L7.95029 18.6179L8.14994 19.4832ZM16.2802 17.6192L8.50654 20.4177C8.74979 20.7319 9.11294 20.9429 9.51989 20.99L16.0443 18.6412L16.2802 17.6192ZM14.3067 21H12.4444L15.7768 19.8004L15.7683 19.8373C15.6112 20.5179 15.0052 21 14.3067 21ZM8.25704 14.5H10.881L7.29769 15.79L7.28264 15.7248C7.13804 15.0982 7.61394 14.5 8.25704 14.5Z"
          fill="#907CB1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7191 4.01427C14.299 3.22937 17.4842 2.1389 19.7888 4.56687C21.8492 6.73765 20.8588 9.00545 19.9056 11.1881C19.3873 12.3746 18.8801 13.536 18.8801 14.6428C18.25 15.75 5 15.5 4.61272 14.6428C4.61272 13.3612 4.1848 12.0269 3.76981 10.733C3.08622 8.60155 2.43772 6.5795 3.79485 5.08235C5.97585 2.67634 8.66025 2.16197 11.75 4.56684C11.75 4.56684 11.7551 4.56333 11.765 4.55664L15.1735 7.038C15.3967 7.20055 15.7094 7.1513 15.872 6.92805C16.0345 6.7048 15.9853 6.3921 15.762 6.22955L12.7191 4.01427Z"
          fill="#907CB1"
        />
      </svg>
    </>
  );
}

export { Onto };
