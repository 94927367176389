import React from "react";
import { TreatmentTop } from "./TreatmentTop";
import { Treatments } from "./Treatments";
import { Advantages } from "./Advantages";
function TreatmentsWrapper() {
  return (
    <>
      <div className="treatments--wrapper">
        <TreatmentTop />
        <Treatments />
        <Advantages />
      </div>
    </>
  );
}

export { TreatmentsWrapper };
