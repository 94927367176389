import * as React from "react";
import { ServicesPage } from "../Components/ServicesPage/ServicesPage";
import { Footer } from "../Components/Footer";
function services() {
  return (
    <>
      <ServicesPage />
      <Footer />
    </>
  );
}

export default services;
