import React from "react";

function Ondoto() {
  return (
    <>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6392 4.6059C17.2934 1.88437 14.0353 3.32087 12.5478 4.21317L15.3274 6.62215C15.5361 6.803 15.5587 7.1188 15.3778 7.32745C15.197 7.53615 14.8812 7.5587 14.6726 7.37785L10.9226 4.12785C10.901 4.10919 10.8815 4.0891 10.8639 4.06784C8.1883 2.24498 5.82805 2.8125 3.89175 5.1648C2.15782 7.27125 3.34 11.0683 4.18984 13.7978H4.18985C4.39248 14.4486 4.57621 15.0387 4.69702 15.5308C5.3245 18.0865 5.9248 19.6791 7.29365 20.8316C7.97155 21.4024 8.7402 20.4215 9.55115 19.3867C10.2543 18.4895 10.9892 17.5518 11.7243 17.5496C12.4474 17.5473 13.1706 18.4836 13.864 19.3811C14.6643 20.4171 15.4248 21.4016 16.0991 20.8316C17.2099 19.8928 17.6766 19.2197 18.303 17.1243C17.751 17.3659 17.1411 17.5 16.5 17.5C14.0147 17.5 12 15.4853 12 13C12 10.5147 14.0147 8.5 16.5 8.5C18.2204 8.5 19.7153 9.4654 20.4725 10.884L20.5634 10.6231C21.1937 8.62095 21.3377 6.57655 19.6392 4.6059Z"
          fill="#907CB1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 16.5C18.433 16.5 20 14.933 20 13C20 11.067 18.433 9.5 16.5 9.5C14.567 9.5 13 11.067 13 13C13 14.933 14.567 16.5 16.5 16.5ZM16.5 17.5C18.9853 17.5 21 15.4853 21 13C21 10.5147 18.9853 8.5 16.5 8.5C14.0147 8.5 12 10.5147 12 13C12 15.4853 14.0147 17.5 16.5 17.5Z"
          fill="#907CB1"
        />
        <path
          d="M16 11C16 10.7239 16.2239 10.5 16.5 10.5C16.7761 10.5 17 10.7239 17 11V15C17 15.2761 16.7761 15.5 16.5 15.5C16.2239 15.5 16 15.2761 16 15V11Z"
          fill="#907CB1"
        />
        <path
          d="M14.5 13.5C14.2239 13.5 14 13.2761 14 13C14 12.7239 14.2239 12.5 14.5 12.5H18.5C18.7761 12.5 19 12.7239 19 13C19 13.2761 18.7761 13.5 18.5 13.5H14.5Z"
          fill="#907CB1"
        />
      </svg>
    </>
  );
}

export { Ondoto };
