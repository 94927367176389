import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image } from "../Image";
import { TreatModal } from "./TreatModal";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
const nonMutable = {
  id: "",
  state: false,
  title: "",
  img: "",
  body: "",
};
function Treatments() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTreatments {
        nodes {
          treatmentName
          body {
            body
          }
          mobileImage {
            gatsbyImageData(layout: FIXED)
          }
          pcImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const {
    allContentfulTreatments: { nodes },
  } = data;

  const [TreatLayout, setTreatLayout] = useState({ ...nonMutable });
  return (
    <>
      <div className="treatments">
        <AnimateSharedLayout type="crossfade">
          <ul>
            {nodes.map((n) => {
              return (
                <motion.li
                  key={n.treatmentName}
                  layoutId={n.treatmentName}
                  

                  onTap={() => {
                    setTreatLayout({
                      id: n.treatmentName,
                      state: true,
                      title: n.treatmentName,
                      img: n.pcImage,
                      body: n.body,
                    });
                  }}
                >
                  <motion.div className="treatment--Title">
                    <motion.h3>
                      {n.treatmentName} <span>Treatment</span>
                    </motion.h3>
                  </motion.div>
                  <Image classId="treatments--image" image={n.pcImage}  alt='dental care'/>
                </motion.li>
              );
            })}
          </ul>
          <AnimatePresence>
            {TreatLayout.state && (
              <TreatModal
                Layout={TreatLayout}
                setLayout={setTreatLayout}
                nonMutable={nonMutable}
              />
            )}
          </AnimatePresence>
          <div className="quote">
            <p>
              <q>Prevention is Better than cure</q>
            </p>
          </div>
        </AnimateSharedLayout>
      </div>
    </>
  );
}

export { Treatments };
